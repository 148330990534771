<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <template v-if="legalitas.length > 0">
      <v-card
        v-for="(v, i) in legalitas"
        :key="'legalitas-' + i"
        class="px-6 py-8 mb-4"
      >
        <v-row>
          <v-col class="text-center txt20-black-bold">
            {{ v.title || '-' }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="txt16-black"
            v-html="v.content"
          />
        </v-row>

        <template v-if="v.files.length > 0">
          <div
            v-for="(x, y) in v.files"
            :key="'file-' + y"
            class="d-flex justify-space-between align-center card-file mt-4 mb-2 px-3"
          >
            <span
              class="txt12-blue"
              style="cursor: pointer"
              @click="download(x)"
            >
              {{ x.file_name }}
            </span>

            <span>
              <v-icon
                color="#FFC709"
                style="cursor: pointer"
                @click="download(x)"
              >
                mdi-tray-arrow-down
              </v-icon>
            </span>
          </div>
        </template>
      </v-card>
    </template>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      legalitas: [],
    }),

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        axios.post('/v1/admin/legalitas', {}).then((res) => {
          if (res.data.status === 200) {
            this.legalitas = res.data.data
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      download (p) {
        window.open(p.url_download, '_blank')
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt12-blue {
  @extend .p-2;
  color: $blue-1;
}

.txt16-black {
  @extend .p-1;
  color: $black-1;
}

.txt20-black-bold {
  @extend .h-5;
  font-weight: bold;
  color: $black-1;
}

.card-file {
  width: 376px;
  height: 44px;
  background: #FAFAFA;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
</style>
